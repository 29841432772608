import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/zh';

const About = (props) => (
  <Layout bodyClass="page-about" location={props.location}>
    <SEO title="About Us"/>
    <div className="intro heading">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center mb-2">關於我們</h1>
          </div>
        </div>
      </div>
    </div>

    <div className="container pb-3 pt-3">
      <div className="row">
        <div className="col-10 mb-1 offset-1">
          <div className="about">
            <div className="about-meta">
              <h2 className="about-title text-center text-dark">團隊</h2>
            </div>
            <div
              className="about-content"
            >
              <blockquote>
                <p
                  className="text-center">在邁升企業顧問團隊中，我們的成員在各大洲的不同領域都有豐富的業務經驗。透過來自不同地域文化的實踐經驗，我們將為您業務度身制定創新及可行的解決方案。</p>
              </blockquote>
            </div>
          </div>
        </div>

        <div className="col-10 mb-1 offset-1">
          <div className="about">
            <div className="about-meta">
              <h2 className="about-title text-center text-dark">展望</h2>
            </div>
            <div
              className="about-content"
            >
              <blockquote>
                <p
                  className="text-center">我們的願景很簡單，但富有意義：成為您業務的解決方案。借助邁升企業顧問，您將享受到有效地運用時間和資源以部署和擴展您的核心業務。我們隨時歡迎您來找我們，我們將竭誠為您解決業務疑難。</p>
              </blockquote>
            </div>
          </div>
        </div>

        <div className="col-10 mb-1 offset-1">
          <div className="about">
            <div className="about-meta">
              <h2 className="about-title text-center text-dark">未來</h2>
            </div>
            <div
              className="about-content"
            >
              <blockquote>
                <p className="text-center">我們期待將您加入我們享有聲望的客戶群，當中包括本地公司以至跨國企業。歡迎與我們討論您業務上進一步的發展！</p>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default About;
